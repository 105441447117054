import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Text from "../components/Text"

import organizations from "../cms/organizations"

export default () => (
  <Layout>
    <Head title="A Few Recommended Organizations" />

    <PageHeading text="A Few Recommended Organizations" centered />

    <Text
      style={css`
        max-width: 40rem;
        margin: 0 auto;
        margin-bottom: 3rem;
      `}
      centered
    >
      {organizations.text}
    </Text>

    <div
      css={css`
        max-width: 30rem;
        margin: 0 auto;
      `}
    >
      {organizations.organizations.map(data => (
        <a
          key={data.name}
          href={data.URL.startsWith("http") ? data.URL : "http://" + data.URL}
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            text-decoration-color: hsla(166, 51%, 26%, .4);
            max-width: max-content;
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: .7rem;
            display: block;
          `}
        >
          {data.name}
        </a>
      ))}
    </div>
  </Layout>
)